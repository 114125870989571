import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    minHeight:'50vh',
    maxHeight:'90vh'
  },
  carousel: {
    '& .carousel.carousel-slider': {
      height: '800px',
      [theme.breakpoints.down('sm')]: {
        height: '60%',
      },
      [theme.breakpoints.down('md')]: {
        height: '60%',
      }
    }
  },
  carouselContent: {
    position: 'absolute',
    top: '100px',
    left: '60%',
    marginLeft: '-45%',
    margin: '0 auto',
    maxWidth: '630px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      top: 20
    }
  },
  subHeadline: {
    padding: '10px',
    fontSize: '2rem',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  headline: {
    color: '#fff',
    padding: '10px',
    paddingBottom: '0px',
    fontSize: '4rem',
    fontWeight: 'bold',
    textShadow: `-1px -1px 0 ${theme.palette.common.black}, 1px -1px 0 ${theme.palette.common.black}, -1px 1px 0 ${theme.palette.common.black}, 1px 1px 0 ${theme.palette.common.black}`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    }
  }
}));

function Banner(props) {
  const classes = useStyles();
  return <div className={classes.root}><Carousel className={classes.carousel} showStatus={false} infiniteLoop={true}
                                                 autoPlay={true} interval={10000}
                                                 showThumbs={false}>
    {props.pageDetails.map((details, index) => <div key={index}>
      <img src={details.image} />
      <div className={classes.carouselContent}>
        <Typography variant='h1' className={classes.headline}>{details.headline}</Typography>
        <Typography variant='subtitle2' className={classes.subHeadline}>{details.subHeadline}</Typography>
      </div>
    </div>)}
  </Carousel></div>;
}

export default Banner;
