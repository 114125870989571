import {ExternalLink} from '@dbg-ui/components';
import {limitWidth} from '@dbg-ui/components/layout';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    bottom: '0',
    paddingTop:'15px',
    [theme.breakpoints.down('sm')]: {
     height: '40%',
    },
    [theme.breakpoints.down('md')]: {
      height: '40%',
    }
  },
  elements: {
    ...limitWidth,
    fontFamily: 'clearSans_regular,Arial,Verdana,Helvetica,sans-serif',
    fontSize: '0.875rem',
    letterSpacing: '1px',
    color: 'white',
    display: 'flex',
    '& ul': {
      listStyleType: 'none',
      margin: 0,
      padding: theme.spacing(3)
    },
    '& li': {
      display: 'inline-block',
      marginRight: '20px',
    },
    '& a:hover': {
      textDecoration: 'underline'
    },
    '& a': {
      color: 'white',
      textDecoration: 'none'
    }
  }
}));

function Footer() {
  const classes = useStyles();
  const {t} = useTranslation();
  return <>
    <div className={classes.root}>
      <div className={classes.elements}>
        <ul>
          <li>
            <ExternalLink href={t('footer.link.copyright')}>
              © {moment().format('YYYY')} {t('footer.text.copyright')}
            </ExternalLink>
          </li>
          <li><ExternalLink href={t('footer.link.imprint')}>{t('footer.text.imprint')}</ExternalLink></li>
          <li><ExternalLink href={t('footer.link.disclaimer')}>{t('footer.text.disclaimer')}</ExternalLink></li>
          <li><ExternalLink href={t('footer.link.privacy')}>{t('footer.text.privacy')}</ExternalLink></li>
          <li><ExternalLink href={t('footer.link.trademarks')}>{t('footer.text.trademarks')}</ExternalLink></li>
        </ul>
      </div>
    </div>
  </>;
}

export default Footer;