import {
    REQUEST_USER_CONNECTIONS,
    REQUEST_USER_CONNECTIONS_FINISHED
} from './UserConnectionsAction';


export default function userConnectsReducer(state = {}, action) {

    switch (action.type) {
        case REQUEST_USER_CONNECTIONS:
            return {
                ...state,
                isLoading: true,
                connections: [],
                error: null
            };
        case REQUEST_USER_CONNECTIONS_FINISHED:
            if (action.payload?.errors) {
                return {
                    ...state,
                    isLoading: false,
                    connections: [],
                    error: action.payload
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    connections: action.payload,
                    error: null
                };
            }
        default:
            return state;
    }
}