
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import CookieScript from './components/CookieScript';
import Footer from './components/Footer';
import Header from './components/Header';
import { Home, NotFound} from './pages';
import UserConnections from "./pages/UserConnections";

function Router() {
    /* const authContext = useAuthContext(); */
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/user-connections' element={<UserConnections/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
            <Footer/>
            <CookieScript/>
        </BrowserRouter>
    );
}

export default Router;
