import React from 'react';
import {Alert, Collapse, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const ErrorAlert = ({ open, setOpen, severity  }) => {
    return (
        <Collapse in={open}>
            <Alert severity={severity}
                   action={
                       <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                               setOpen(false);
                           }}
                       >
                           <CloseIcon fontSize="inherit" />
                       </IconButton>
                   }>
                No records found!
            </Alert>
        </Collapse>
    );
};

export default ErrorAlert;
