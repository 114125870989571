import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: '100vh',
    paddingBottom: '0px' // footer height
  },
}));

function ContentWrap(props) {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}

export default ContentWrap;