import {AuthProvider} from '@dbg-ui/auth';
import theme from '@dbg-ui/theme';
import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material';
import {Provider} from 'react-redux';
import rootStore from '../stores/rootStore';
import ContentWrap from './components/ContentWrap';
import Router from './Router';

function App() {
  return (
    <Provider store={rootStore()}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <ContentWrap>
            <CssBaseline />
            <AuthProvider>
              <Router />
            </AuthProvider>
          </ContentWrap>
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
