import {applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { legacy_createStore as createStore} from 'redux'

export default function rootStore(initialState = {}) {
  return createStore(rootReducer(), initialState, composeWithDevTools(applyMiddleware(thunk)));
}

