import {Main} from '@dbg-ui/components/layout';
import {Grid, Typography} from '@mui/material';
import React from 'react';

function Dashboard() {

  return <Main>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant='h1'>Protected content here</Typography>
      </Grid>
    </Grid>
  </Main>;
}

export default Dashboard;
