import {Main} from '@dbg-ui/components/layout';
import {Button, Grid, Typography} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';

function NotFound() {
  return <Main>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h2'>Error</Typography>
        <Typography variant='subtitle1'>The page you have chosen cannot be displayed</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>Click the button below to return to the landing page</Typography>
      </Grid>
      <Grid item>
        <Button component={Link} to='/'>Home</Button>
      </Grid>
    </Grid>
  </Main>;
}

export default NotFound;
