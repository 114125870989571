import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Banner from '../components/Banner';

const useStyles = makeStyles((theme) => ({
 title: {
   fontSize: '3.0rem',
   fontWeight: 'bold',
   textShadow: 'none',
 },
}));

function Home() {
 const { t } = useTranslation();
 const classes = useStyles();
 const imageUrl = process.env.PUBLIC_URL + '/images/banner/streaming-min.jpg';
 const [originalImageBlob, setOriginalImageBlob] = useState(null);

 useEffect(() => {
   const fetchImage = async () => {
     try {
       const response = await fetch(imageUrl);
       const blob = await response.blob();
       setOriginalImageBlob(blob);
     } catch (error) {
       console.error('Error fetching image:', error);
     }
   };

   fetchImage();
 }, []);


 const bannerDetails = [
   {
     image: imageUrl,
     headline: t('home.headline'),
     subHeadline: t('home.subHeadline'),
   },
 ];

 return (
<div>
<Banner pageDetails={bannerDetails} />
</div>
 );
}

export default Home;