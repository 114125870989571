import {v4} from 'uuid';

export default class HttpErrorResponseModel {
  id = v4();
  status = 0;
  message = '';
  errors = [];
  url = '';
  raw = null;
}
