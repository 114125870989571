import React from 'react';
import TextField from '@mui/material/TextField';
import {DesktopDatePicker} from "@mui/x-date-pickers";

const DatePicker = ({ label, inputFormat, value, onChange }) => {
    return (
        <DesktopDatePicker
            label={label}
            inputFormat={inputFormat}
            value={value}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} />}
        />
    );
};
export default DatePicker;
