import {useAuthContext} from '@dbg-ui/auth';
import {limitWidth, MainNavigation, MainNavigationLink, MetaNavigation} from '@dbg-ui/components/layout';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Drawer, IconButton, Link, Toolbar, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Account from './Account';

const disableForSmallScreens = theme => {
  return {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  };
};

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1
  },
  metaNavigation: {
    ...disableForSmallScreens(theme),
    justifyContent:"flex-end",

  },
  switch: {
    marginLeft: 28,
    marginRight: 8
  },
  toolbar: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },
    ...limitWidth
  },
  logo: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: 200
    }
  },
  account: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  drawerAccount: {
    marginTop: 32,
    '& .MuiButton-iconSizeMedium': {
      display: 'none'
    },
    '& div': {
      display: 'block'
    },
    '& .MuiButton-label': {
      color: '#fff',
      padding: '0 32px 8px 16px'
    }
  },
  drawerMenu: {
    '& .MuiDrawer-paper': {
      paddingTop: 32,
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    '& .MuiLink-root': {
      padding: 16
    }
  },
}));

function Header() {
  const authContext = useAuthContext();
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const handleLanguageSwitch = () => {
    const newLang = i18n.language === 'en' ? 'de' : 'en';
    i18n.changeLanguage(newLang);
  };

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (<>
      <MetaNavigation className={classes.metaNavigation} >
        <Link underline='hover' className={classes.switch} onClick={handleLanguageSwitch}>
          {t('header.metaNavigation.languageSwitch')}
        </Link>
      </MetaNavigation>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.flexGrow}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/logo.svg`} alt='Deutsche Börse Group' />
          </div>
          <Box className={classes.account}>
            <Account />
          </Box>
        </Toolbar>

        <MainNavigation>
          <Box sx={{flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}>
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              className={classes.drawerMenu}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MainNavigationLink to='/'>{t('header.mainNavigation.home')}</MainNavigationLink>

              <div className={classes.drawerAccount}>
                <Account />
              </div>
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            <MainNavigationLink to='/'>{t('header.mainNavigation.home')}</MainNavigationLink>
            <>
              {authContext.isAuthenticated ? (
                  <MainNavigationLink to="/user-connections">
                    {t('header.mainNavigation.userConnections')}
                  </MainNavigationLink>
              ) : null}
            </>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <MainNavigationLink to='https://www.mds.deutsche-boerse.com/mds-en/real-time-data'>
              Market Data + Services
            </MainNavigationLink>
          </Box>
        </MainNavigation>
      </AppBar>
    </>
  );
}

export default Header;
