import {getToModel} from "../../utilities/EffectUtility";
import UserConnectionsModel from "../../models/userinfo/UserConnectionsModel";
import {DEFAULT_API_USER_CONNECTIONS_ENDPOINT} from "../../constants/DefaultApiEndpoints";
import configuration from '../../utilities/configuration';


export async function requestUserConnectionsEffect(username, startDate , endDate) {
    let endpoint = `${configuration.apiGateway}${DEFAULT_API_USER_CONNECTIONS_ENDPOINT.replace(':username', username)}`;
    if(startDate || endDate){
        const params = new URLSearchParams();
        if (startDate!=="") {
            params.append('startDate', startDate);
        }
        if (endDate!=="") {
            params.append('endDate', endDate);
        }
        endpoint += `?${params.toString()}`;
    }
    return await getToModel(UserConnectionsModel, endpoint);
}
