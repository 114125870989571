import {AuthUtility} from '@dbg-ui/auth';
import HttpErrorResponseModel from '../models/HttpErrorResponseModel';
import * as HttpUtility from './HttpUtility';

export async function getToModel(Model, endpoint, params = {}, secured = true) {
  const response = await _sendRequestWithBearer(HttpUtility.get, endpoint, params, secured);
  return _restModelCreator(Model, response);
}

export async function postToModel(Model, endpoint, data = {}, secured = true) {
  const response = await _sendRequestWithBearer(HttpUtility.post, endpoint, data, secured);

  return _restModelCreator(Model, response);
}

export async function putToModel(Model, endpoint, data = {}, headers, secured = true) {
  const response = await _sendRequestWithBearer(HttpUtility.put, endpoint, data, secured);

  return _restModelCreator(Model, response);
}

export async function deleteToModel(Model, endpoint, secured = true) {
  const response = await _sendRequestWithBearer(HttpUtility.del, endpoint, secured);

  return _restModelCreator(Model, response);
}

function _restModelCreator(Model, response) {
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  // some AWS endpoints return string-encoded JSON
  if (typeof response.data === 'string') {
    response.data = JSON.parse(response.data);
  }

  if (!Array.isArray(response.data)) {
    return new Model(response.data);
  } else {
    return response.data.map((json) => new Model(json));
  }
}

const JWT_IS_EXPIRED_ERROR_MESSAGE = 'Jwt is expired';

async function _sendRequestWithBearer(requestFunction, endpoint, params = {}, secured) {
  let response = await requestFunction(endpoint, secured ? AuthUtility.getBearerHeader() : {}, params);

  if (response instanceof HttpErrorResponseModel) {
    if (response.raw?.data === JWT_IS_EXPIRED_ERROR_MESSAGE) {
      const resendResponse = await AuthUtility.getTokenFromRefreshToken();
      if (resendResponse) {
        response = await requestFunction(endpoint, AuthUtility.getBearerHeader(), params);
      }
    }
  }

  return response;
}
