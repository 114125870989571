import {ExternalLink} from '@dbg-ui/components';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import './CookieScript.css';

function CookieScript() {
  const [open, setOpen] = useState(false);
  const [acceptPerformance, setAcceptPerformance] = useState(false);
  const [acceptTargeting, setAcceptTargeting] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const saveAndClose = (newAcceptPerformance = acceptPerformance, newAcceptTargeting = acceptTargeting) => {
    setAcceptPerformance(newAcceptPerformance);
    setAcceptTargeting(newAcceptTargeting);
    setOpen(false);
    setShowDetails(false);
  };

  const {t} = useTranslation();
  return open
    ?
    <div id='cookiescript_injected'>
      <div id='cookiescript_toppart'>
        <div id='cookiescript_leftpart'>
          <div id='cookiescript_description'>
            <span>{t('cookiescript.banner.description')}</span>
            <ExternalLink id='cookiescript_readmore' href='https://www.deutsche-boerse.com/dbg-en/meta/privacy-notice'>
              {t('cookiescript.banner.privacyNotice')}
            </ExternalLink>
          </div>
          <div id='cookiescript_checkboxs'>
            <div className='cookiescript_checkbox'>
              <div className='mdc-checkbox'>
                <input id='cookiescript_category_strict' data-cookiescript='checkbox-input' type='checkbox'
                       className='mdc-checkbox__native-control cookiescript_checkbox_input' value='strict' disabled checked />
                <div className='mdc-checkbox__background'>
                  <svg className='mdc-checkbox__checkmark' viewBox='0 0 24 24'>
                    <path className='mdc-checkbox__checkmark-path' fill='none' d='M1.73,12.91 8.1,19.28 22.79,4.59' />
                  </svg>
                </div>
              </div>
              <label className='cookiescript_checkbox_label' htmlFor='cookiescript_category_strict'>
                <span className='cookiescript_checkbox_text'>{t('cookiescript.banner.categoryStrict')}</span>
              </label>
            </div>
            <div className='cookiescript_checkbox'>
              <div className='mdc-checkbox'>
                <input id='cookiescript_category_performance' data-cookiescript='checkbox-input' type='checkbox'
                       className='mdc-checkbox__native-control cookiescript_checkbox_input' value='performance'
                       checked={acceptPerformance} onChange={() => setAcceptPerformance(!acceptPerformance)} />
                <div className='mdc-checkbox__background'>
                  <svg className='mdc-checkbox__checkmark' viewBox='0 0 24 24'>
                    <path className='mdc-checkbox__checkmark-path' fill='none' d='M1.73,12.91 8.1,19.28 22.79,4.59' />
                  </svg>
                </div>
              </div>
              <label className='cookiescript_checkbox_label' htmlFor='cookiescript_category_performance'>
                <span className='cookiescript_checkbox_text'>{t('cookiescript.banner.categoryPerformance')}</span>
              </label>
            </div>
            <div className='cookiescript_checkbox'>
              <div className='mdc-checkbox'>
                <input id='cookiescript_category_targeting' data-cookiescript='checkbox-input' type='checkbox'
                       className='mdc-checkbox__native-control cookiescript_checkbox_input' value='targeting'
                       checked={acceptTargeting} onChange={() => setAcceptTargeting(!acceptTargeting)} />
                <div className='mdc-checkbox__background'>
                  <svg className='mdc-checkbox__checkmark' viewBox='0 0 24 24'>
                    <path className='mdc-checkbox__checkmark-path' fill='none' d='M1.73,12.91 8.1,19.28 22.79,4.59' />
                  </svg>
                </div>
              </div>
              <label className='cookiescript_checkbox_label' htmlFor='cookiescript_category_targeting'>
                <span className='cookiescript_checkbox_text'>{t('cookiescript.banner.categoryTargeting')}</span>
              </label>
            </div>
          </div>
          <div id='cookiescript_manage_wrap' tabIndex='0' role='button' onClick={() => setShowDetails(!showDetails)}>
            <div id='cookiescript_manage'>
              <svg id='cookiescript_manageicon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.62 9.57'>
                <g id='Layer_2'>
                  <g id='Layer_1-2'>
                    <path className='cookiescript_gear'
                          d='M9.46,6.06l-1.1-.78c0-.16.06-.31.06-.47a1.27,1.27,0,0,0-.06-.47L9.57,3.4l-1.15-2L7,1.93a2.74,2.74,0,0,0-.83-.47L6,0H3.61L3.35,1.46a7.14,7.14,0,0,0-.79.47L1.15,1.36,0,3.4l1.15.94c0,.16,0,.31,0,.47a1.51,1.51,0,0,0,0,.47l-1,.78A.75.75,0,0,0,0,6.17l1.15,2,1.41-.58a2.49,2.49,0,0,0,.84.47l.21,1.47H6a.53.53,0,0,1,0-.21L6.22,8.1a4,4,0,0,0,.84-.47l1.41.58,1.15-2A.75.75,0,0,0,9.46,6.06Zm-4.65.19A1.47,1.47,0,1,1,6.28,4.78,1.47,1.47,0,0,1,4.81,6.25Z' />
                  </g>
                </g>
              </svg>
              <span>{showDetails ? t('cookiescript.banner.hideDetails') : t('cookiescript.banner.showDetails')}</span>
            </div>
          </div>
        </div>
        <div id='cookiescript_rightpart'>
          <div id='cookiescript_buttons'>
            {acceptPerformance !== acceptTargeting
              ?
              <div id='cookiescript_save' tabIndex='0' role='button' onClick={() => saveAndClose()}>
                {t('cookiescript.banner.save')}
              </div>
              :
              <div id='cookiescript_accept' tabIndex='0' role='button' onClick={() => saveAndClose(true, true)}>
                {t('cookiescript.banner.accept')}
              </div>
            }
            <div id='cookiescript_reject' tabIndex='0' role='button' onClick={() => saveAndClose(false, false)}>
              {t('cookiescript.banner.reject')}
            </div>
          </div>
        </div>
      </div>
      <div id='cookiescript_close' tabIndex='0' role='button' onClick={() => saveAndClose()}>×</div>
    </div>
    :
    <div id='cookiescript_badge' onClick={() => setOpen(true)}>
      <div id='cookiescript_badgetext'>
        {t('cookiescript.badge.text')}
      </div>
    </div>;
}

export default CookieScript;
