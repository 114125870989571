import {createThunkEffect} from '../../utilities/ActionUtility';
import {requestUserConnectionsEffect} from './UserConnectionsEffect';

export const REQUEST_USER_CONNECTIONS = 'REQUEST_USER_CONNECTIONS';
export const REQUEST_USER_CONNECTIONS_FINISHED = 'REQUEST_USER_CONNECTIONS_FINISHED'


export const requestUserConnections = (username, startDate = "", endDate = "") => {
    return async (dispatch) => {
        return await createThunkEffect(dispatch, REQUEST_USER_CONNECTIONS, requestUserConnectionsEffect,  username, startDate, endDate)
    }
}

