import {useAuthContext} from '@dbg-ui/auth';
import {AccountCircle, LockOpen} from '@mui/icons-material';
import {Button, Grid} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';


function Account() {
  const dispatch = useDispatch();
  const authContext = useAuthContext();
  useEffect(() => {
    authContext.initAuth();
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    /*
    if (authContext.isAuthenticated) {
      dispatch(requestUserInfo({
        userName: authContext.user.name,
        firstname: authContext.user.given_name,
        lastname: authContext.user.family_name
      }));
    }*/
  }, [dispatch, authContext.isAuthenticated, authContext.user]);

  const {t} = useTranslation();

  return authContext.isAuthenticated ? (<Grid container spacing={1}>
    <Grid item>
      <Button /*component={Link} to='/user'*/ startIcon={<AccountCircle />} variant='text' color='inherit'>
        {authContext.user.given_name} {authContext.user.family_name}
      </Button>
    </Grid>
    <Grid item>
      <Button startIcon={<LockOpen />} variant='text' color='inherit' onClick={authContext.logoutWithRedirect}>
        {t('account.text.logout')}
      </Button>
    </Grid>
  </Grid>) : (
    <Button startIcon={<LockOpen />} variant='text' color='inherit' onClick={authContext.requestAuth}>
      {t('account.text.login')}
    </Button>
  );
}

export default Account;
