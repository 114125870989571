export const MAX_DATE_VALUE = 99991231;
export const MAX_DATE = new Date(Date.UTC(9999, 11, 31));

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

export const parseDateToAPIFormat = (dateString) => {
  if(!dateString) return "";
  if (dateString >= MAX_DATE) return MAX_DATE_VALUE;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);
  return `${year}${month}${day}`;
}

export const convertTimestamp = timestamp => {
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  };
  return new Intl.DateTimeFormat('en-US', dateOptions).format(timestamp);
};


