import React from 'react'
import {Main} from "@dbg-ui/components/layout";
import {SimpleTile, SortedTable} from '@dbg-ui/components';
import {useDispatch, useSelector} from "react-redux";
import {useAuthContext} from "@dbg-ui/auth";
import {useTranslation} from "react-i18next";
import { Button, Grid} from "@mui/material";
import { useEffect, useState } from 'react';
import {convertTimestamp, parseDateToAPIFormat} from "../../utilities/TimeUtility";
import { LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {requestUserConnections} from "../../stores/userconnects/UserConnectionsAction";
import ErrorAlert from "../components/Alerts";
import DatePicker from "../components/DataPicker";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
 table:{
     marginTop:20,
     minHeight: '20vh',
     maxHeight: '50vh',
     overflowY: 'scroll',
     overflowX: 'hidden'
 },
 gridContainer:{
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center'
    }
}));

function UserConnections() {
    const dispatch = useDispatch();
    const authContext = useAuthContext();
    const {t} = useTranslation();
    const [username, setUsername] = useState("");
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month'));
    const connections= useSelector((state) => state.userConnects.connections);
    const error = useSelector((state) => state.userConnects.error);
    const [open, setOpen] = useState(true);
    const classes = useStyles();

    useEffect(() => {
    }, [error]);

    useEffect(() => {
        if (authContext.isAuthenticated) {
            setUsername(authContext.user["cognito:username"])
        }
    }, [authContext.isAuthenticated, authContext.user]);

    useEffect(async () => {
        if(username){
            dispatch(requestUserConnections(username, "", ""))
        }
    }, [username]);


    const handleSubmit = () => {
        const startDateC = parseDateToAPIFormat(startDate);
        const endDateC = parseDateToAPIFormat(endDate);
        dispatch(requestUserConnections(username, startDateC, endDateC));
    };


    //COLUMN AND DATA FOR SORTED TABLE
    const columns = [
        {id: 'a', label: t('userConnections.tableRows.startTimestamp')},
        {id: 'b', label: t('userConnections.tableRows.endTimestamp')},
        {id: 'c', label: t('userConnections.tableRows.featureCategory')},
        {id: 'd', label: t('userConnections.tableRows.stream')},
        {id: 'e', label: t('userConnections.tableRows.writtenBytes')}
         

    ];

    let data = [];
    if(connections) {
        data = connections.map(entry => {
            console.log(entry.stream.value)
            return {
                a: convertTimestamp(entry.startTimestamp),
                b: convertTimestamp(entry.endTimestamp),
                c: entry.featureCategory,
                d: entry.stream,
                e: entry.writtenBytes


            };
        });
    }

    return <div>
        <Main>
            {error &&
                <SimpleTile>
                    <ErrorAlert severity = "warning" open={open} setOpen={setOpen} />
                </SimpleTile>
            }
            <SimpleTile  color='secondary' title= {t('userConnections.tableHeader')}>
                <Grid container spacing={3} columns={16}  className={classes.gridContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={5}>
                            <DatePicker
                                label={t('userConnections.dataFilter.startDate')}
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <DatePicker
                                label={t('userConnections.dataFilter.endDate')}
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={4}>
                        <Button onClick={handleSubmit} >Submit</Button>
                    </Grid>
                </Grid>
                <SortedTable columns={columns} data={data} className={classes.table} />
            </SimpleTile>
        </Main>
    </div>;
}

export default UserConnections;