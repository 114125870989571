import {BaseModel} from 'sjs-base-model';

export default class UserConnectionsModel extends BaseModel {
    connectionId = '';
    userId = '';
    writtenBytes = '';
    startTimestamp = '';
    endTimestamp = '';
    featureCategory='';
    stream = '';

    constructor(data) {
        super();
        this.update(data);
    }
}